function trimSlashes(str) {
    return str.replace(/^\/+|\/+$/g, "");
}
/**
 * Create an API url out of the url and optional base.
 */
export function createApiUrl(url, base) {
    /**
     * Ensures the base URL ends with a trailing slash, treating the final segment as a directory path
     * rather than a filename. Without this, the last segment would be dropped when resolving relative URLs.
     */
    const trimmedBase = base ? `${trimSlashes(base)}/` : undefined;
    // Remove leading/trailing slashes from URL when a base is provided to ensure proper path joining without creating unintended absolute paths
    const trimmedUrl = base ? trimSlashes(url) : url;
    return new URL(trimmedUrl, trimmedBase);
}
