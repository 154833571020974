import { UserButton } from "@clerk/clerk-react";
import { Anchor, AppShell, Box, Burger, Group } from "@mantine/core";
import * as React from "react";
import * as Router from "react-router-dom";
import { useSnapshot } from "valtio";
import { ConnectDeviceModal } from "@/components/integrated-devices/connect-device-modal";
import { LogoTitle } from "@/components/logo-title";
import { Navigation } from "@/components/navigation";
import { navigation } from "@/utils/navigation";
import { DelayedLoader } from "@/components/delayed-component";

export default function MainLayout() {
  const { opened } = useSnapshot(navigation);

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: 250,
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
    >
      <AppShell.Header p="xs">
        <Group justify="space-between">
          <Burger
            opened={opened}
            onClick={() => {
              navigation.opened = !opened;
            }}
            hiddenFrom="sm"
            size="sm"
          />
          <Anchor
            component={Router.NavLink}
            c="unset"
            underline="never"
            to="/home"
          >
            <LogoTitle />
          </Anchor>
          <Group>
            <ConnectDeviceModal size="button" />
            <UserButton />
          </Group>
        </Group>
      </AppShell.Header>
      <AppShell.Navbar py="md">
        <Navigation />
      </AppShell.Navbar>
      <AppShell.Main>
        <React.Suspense fallback={<DelayedLoader />}>
          <Box p="lg">
            <Router.Outlet />
          </Box>
        </React.Suspense>
      </AppShell.Main>
    </AppShell>
  );
}
