import * as React from "react";
import { Page } from "../_page";
import { AgeVerificationModal } from "../../components/age-verification-modal";
import { LoginBar } from "../landing-page/components/login-bar";
import { DelayedLoadingOverlay } from "@/components/delayed-component";
import * as Router from "react-router-dom";

export default function LandingLayout() {
  return (
    <Page>
      <AgeVerificationModal />
      <LoginBar />
      <React.Suspense fallback={<DelayedLoadingOverlay />}>
        <Router.Outlet />
      </React.Suspense>
    </Page>
  );
}
