import { handleSecurityError } from "@/store/handle-security-error";
import { proxy, subscribe } from "valtio";

export function createPersistentStore<Schema extends object>({
  key,
  defaultValue,
}: {
  key: string;
  defaultValue: Schema;
}) {
  function loadState() {
    let state = defaultValue;

    // Attempt to retrieve state from local storage and if exists override the default value.
    try {
      const localStorageItem = localStorage.getItem(key);
      if (localStorageItem) {
        const parsedItem: Schema = JSON.parse(localStorageItem);
        state = parsedItem;
      }
    } catch (error) {
      handleSecurityError(error);
    }

    return state;
  }

  // Synchronize state changes with local storage.
  const store = proxy(loadState());
  subscribe(store, () => {
    try {
      localStorage.setItem(key, JSON.stringify(store));
    } catch (error) {
      handleSecurityError(error);
    }
  });

  return store;
}
