import { Anchor, Box, Card, Group } from "@mantine/core";
import { LogoTitle } from "@/components/logo-title";
import { SignedOut, SignInButton, UserButton } from "@clerk/clerk-react";
import { NavLink } from "react-router-dom";

export function LoginBar() {
  return (
    <div style={{ position: "sticky", margin: "auto", maxWidth: 1000 }}>
      <Card radius="lg" withBorder m="md" pr="lg" py="0">
        <Group justify="space-between">
          <Box ml="xs">
            <Anchor component={NavLink} c="unset" underline="never" to="/">
              <LogoTitle size="h3" c="white" bg="none" h={64} />
            </Anchor>
          </Box>
          <Group justify="end">
            <SignedOut>
              <SignInButton>
                <Anchor component="button" fw="bold">
                  Sign in
                </Anchor>
              </SignInButton>
            </SignedOut>
            <UserButton />
          </Group>
        </Group>
      </Card>
    </div>
  );
}
