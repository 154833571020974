import z from "zod";
import { SubscriptionIntervalSchema, SubscriptionVendorSchema, } from "./subscription.js";
export const MembershipTierSchema = z.enum([
    "free",
    "follower",
    "supporter",
    "master",
]);
const membershipTiers = MembershipTierSchema.options;
export function getMembershipIdByTier(membershipTier) {
    switch (membershipTier) {
        case "free": {
            return 1;
        }
        case "follower": {
            return 2;
        }
        case "supporter": {
            return 3;
        }
        case "master": {
            return 4;
        }
    }
}
export function getMembershipTierById(membership_id) {
    switch (membership_id) {
        case 1: {
            return "free";
        }
        case 2: {
            return "follower";
        }
        case 3: {
            return "supporter";
        }
        case 4: {
            return "master";
        }
        default: {
            throw new Error(`Out-of-range membership_id, ${membership_id}`);
        }
    }
}
export function getMembershipTierByCents(amountCents) {
    if (amountCents >= 2000) {
        return "master";
    }
    if (amountCents >= 1000) {
        return "supporter";
    }
    if (amountCents >= 500) {
        return "follower";
    }
    return "free";
}
export const isPatreonSubscriberResponse = z.object({
    linked: z.boolean(),
    subscribed: z.boolean(),
    membershipTier: MembershipTierSchema,
    vendor: SubscriptionVendorSchema,
    interval: SubscriptionIntervalSchema.optional(),
    endDate: z.string().optional(),
    isCancelled: z.boolean(),
    subscriptionId: z.number(),
});
export function hasRequiredMembership(currentMembershipTier, requiredMembershipTier) {
    const currentMembershipIndex = membershipTiers.findIndex((membershipTier) => membershipTier === currentMembershipTier);
    const requiredMembershipIndex = membershipTiers.findIndex((membershipTier) => membershipTier === requiredMembershipTier);
    return currentMembershipIndex >= requiredMembershipIndex;
}
