import { LovenseSocketUrlSchema } from "../schemas/lovense-socket-url.js";
import { ApiClient } from "@fapinstructor/common";
export const lovenseApi = new ApiClient("https://api.lovense-api.com/api/basicApi");
export async function getLovenseSocketUrl(token) {
    const { data } = await lovenseApi
        .post("https://api.lovense-api.com/api/basicApi/getSocketUrl", {
        body: {
            platform: "fapinstructor",
            authToken: token,
        },
    })
        .json(LovenseSocketUrlSchema);
    if (!data) {
        throw new Error("Failed to retrieve lovense socket url.");
    }
    return data;
}
