const env = import.meta.env;

export const CLERK_PUBLISHABLE_KEY: string = env.VITE_CLERK_PUBLISHABLE_KEY;

if (!CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing CLERK_PUBLISHABLE_KEY env var");
}

export const FAPINSTRUCTOR_API: string = env.VITE_FAPINSTRUCTOR_API;

if (!FAPINSTRUCTOR_API) {
  throw new Error("Missing FAPINSTRUCTOR_API env var");
}

export const FAPINSTRUCTOR_WS: string = env.VITE_FAPINSTRUCTOR_WS;

if (!FAPINSTRUCTOR_WS) {
  throw new Error("Missing FAPINSTRUCTOR_WS env var");
}

export const PATREON_CLIENT_ID: string = env.VITE_PATREON_CLIENT_ID;

if (!PATREON_CLIENT_ID) {
  throw new Error("Missing PATREON_CLIENT_ID env var");
}

export const PATREON_OAUTH_REDIRECT_URI: string =
  env.VITE_PATREON_OAUTH_REDIRECT_URI;

if (!PATREON_OAUTH_REDIRECT_URI) {
  throw new Error("Missing PATREON_OAUTH_REDIRECT_URI env var");
}

export const FLAGSMITH_CLIENT_KEY: string = env.VITE_FLAGSMITH_CLIENT_KEY;

if (!FLAGSMITH_CLIENT_KEY) {
  throw new Error("Missing FLAGSMITH_CLIENT_KEY env var");
}

export const LANGUAGETOOL_API: string = env.VITE_LANGUAGETOOL_API;

if (!LANGUAGETOOL_API) {
  throw new Error("Missing LANGUAGETOOL_API env var");
}

export const SCROLLLER_API: string = env.VITE_SCROLLLER_API;

if (!SCROLLLER_API) {
  throw new Error("Missing SCROLLLER_API env var");
}
