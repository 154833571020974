import type { useUser } from "@clerk/clerk-react";

export type ClerkUser = Exclude<
  ReturnType<typeof useUser>["user"],
  null | undefined
>;

export function createScrolllerPricingPageUrl(user: ClerkUser) {
  const scrolllerPricingPage = new URL(
    "https://payments.scrolller.xyz/products/fap-instructor",
  );

  scrolllerPricingPage.searchParams.set("user-id", user.id);

  if (user.primaryEmailAddress) {
    scrolllerPricingPage.searchParams.set(
      "email",
      user.primaryEmailAddress.emailAddress,
    );
  }

  return scrolllerPricingPage.toString();
}
