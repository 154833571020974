import * as React from "react";
import { createPersistentStore } from "@/store/initialize-persistent-store";
import { createObserver, type StrokeStyle } from "@fapinstructor/common";
import * as AutoblowSDK from "@xsense/autoblow-sdk";
import { z } from "zod";
import getStrokeZoneAndVelocity from "../handy/utils/get-stroke-speed-and-distance";
import { adjustSlideByStrokeStyle } from "../handy/handy";
import { DeviceNotConnectedError } from "@xsense/autoblow-sdk";

const MAX_AUTOBLOW_DEVICE_STROKE_LENGTH = 200;

export const AutoblowConfigSchema = z.object({
  deviceToken: z
    .string()
    .min(1, { message: "Please enter your device token." }),
});
type AutoblowConfig = z.infer<typeof AutoblowConfigSchema>;

type DeviceStatus = "connecting" | "connected" | "disconnected" | "error";

class Autoblow {
  config: AutoblowConfig;
  private autoblow?: AutoblowSDK.Autoblow;
  status: DeviceStatus = "disconnected";
  error?: unknown;
  onStatus = createObserver<DeviceStatus>();
  onError = createObserver<unknown>();
  onDeviceTokenChange = createObserver<string>();
  tempo = 0;

  constructor() {
    this.config = createPersistentStore<AutoblowConfig>({
      key: "autoblow",
      defaultValue: {
        deviceToken: "",
      },
    });

    if (this.config.deviceToken) {
      setInterval(() => {
        // If the device ever disconnects, try to reconnect if a device token is present.
        if (!this.isConnected()) {
          this.connect(this.config.deviceToken);
        }
      }, 5000);
      this.connect(this.config.deviceToken);
    }
  }

  async connect(deviceToken: string) {
    this.config.deviceToken = deviceToken;

    if (!this.autoblow) {
      this.autoblow = new AutoblowSDK.Autoblow();
    }

    this.status = "connecting";
    this.onStatus.publish(this.status);
    this.error = undefined;
    this.onError.publish(this.error);

    try {
      await this.autoblow.init(this.config.deviceToken);
      this.status = "connected";
      this.onStatus.publish(this.status);
    } catch (error) {
      this.status = "error";
      this.onStatus.publish(this.status);
      this.error = error;
      this.onError.publish(this.error);
    }
  }

  disconnect() {
    this.config.deviceToken = "";
    this.status = "disconnected";
    this.onStatus.publish(this.status);
  }

  async setTempo(tempo: number, strokeStyle: StrokeStyle) {
    try {
      if (!this.autoblow || !this.isConnected() || this.tempo === tempo) {
        return;
      }
      this.tempo = tempo;

      if (tempo === 0) {
        await this.autoblow.oscillateStop();
      } else {
        const slideSetting = adjustSlideByStrokeStyle([0, 100], strokeStyle);

        const { velocity, strokeZone } = getStrokeZoneAndVelocity(
          tempo,
          slideSetting,
          MAX_AUTOBLOW_DEVICE_STROKE_LENGTH,
        );

        await this.autoblow.oscillateSet(
          velocity,
          strokeZone.min,
          strokeZone.max,
        );
        await this.autoblow.oscillateStart();
      }
    } catch (error) {
      if (error instanceof DeviceNotConnectedError) {
        this.status = "disconnected";
        this.onStatus.publish(this.status);
      } else {
        throw error;
      }
    }
  }

  isConnected() {
    return this.status === "connected";
  }

  isError() {
    return !!this.error;
  }
}

export const autoblow = new Autoblow();

export function useAutoblow() {
  const [status, setStatus] = React.useState<DeviceStatus>(autoblow.status);
  const [error, setError] = React.useState<unknown>(autoblow.error);
  const [deviceToken, setDeviceToken] = React.useState<string>(
    autoblow.config.deviceToken,
  );

  React.useEffect(() => {
    const unsubscribeStatus = autoblow.onStatus.subscribe(setStatus);
    const unsubscribeError = autoblow.onError.subscribe(setError);
    const unsubscribeDeviceToken =
      autoblow.onDeviceTokenChange.subscribe(setDeviceToken);

    return () => {
      unsubscribeStatus();
      unsubscribeError();
      unsubscribeDeviceToken();
    };
  }, []);

  return { status, error, deviceToken };
}
