// The fastest speed the handy can operate at.
const maxDeviceStrokeSpeed = 400;

function calculateMinStrokeLengthReduction(
  slideMin: number,
  maxDeviceStrokeLength: number,
) {
  const reduction = maxDeviceStrokeLength * (slideMin / 100);
  return reduction;
}

function calculateMaxStrokeLengthReduction(
  slideMax: number,
  maxDeviceStrokeLength: number,
) {
  const reduction =
    maxDeviceStrokeLength - maxDeviceStrokeLength * (slideMax / 100);
  return reduction;
}

function calculateStrokeLength(
  slide: [min: number, max: number],
  maxDeviceStrokeLength: number,
) {
  const [slideMin, slideMax] = slide;
  const strokeLength =
    maxDeviceStrokeLength -
    (calculateMinStrokeLengthReduction(slideMin, maxDeviceStrokeLength) +
      calculateMaxStrokeLengthReduction(slideMax, maxDeviceStrokeLength));

  return strokeLength;
}

// The fastest beats per second the handy can operate at with the maximum stroke length.
function getFastestBps(
  maxDeviceStrokeSpeed: number,
  maxDeviceStrokeLength: number,
) {
  const fastestBps = maxDeviceStrokeSpeed / maxDeviceStrokeLength;
  return fastestBps;
}

/**
 * Calculates the stroke velocity and stroke zone from a tempo.
 */
export default function getStrokeZoneAndVelocity(
  tempo: number,
  slide: [min: number, max: number],
  maxDeviceStrokeLength: number,
) {
  const bps = tempo / 60;
  const [slideMin, slideMax] = slide;
  const strokeLength = calculateStrokeLength(slide, maxDeviceStrokeLength);
  const strokeZone = { min: slideMin, max: slideMax }; // %

  // Decrease stroke speed to decrease BPS and then convert speed to velocity %
  const velocity = Math.min(
    Math.trunc(((strokeLength * bps) / maxDeviceStrokeSpeed) * 100),
    100,
  );

  // Shorten stroke distance to increase BPS
  if (velocity === 100) {
    const adjustedStrokeLength =
      (maxDeviceStrokeLength *
        getFastestBps(maxDeviceStrokeSpeed, maxDeviceStrokeLength)) /
      bps;
    const strokeZoneReduction =
      ((strokeLength - adjustedStrokeLength) / maxDeviceStrokeLength) * 100;
    const strokeZoneReductionHalved = Math.trunc(strokeZoneReduction / 2);

    strokeZone.min = slideMin + strokeZoneReductionHalved;
    strokeZone.max = slideMax - strokeZoneReductionHalved;
  }

  return {
    velocity,
    strokeZone,
  };
}
