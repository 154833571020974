import {
  IconActivity,
  IconCoin,
  IconSettings,
  IconTestPipe,
} from "@tabler/icons-react";
import { isDevelopmentMode } from "@/utils/is-development-mode";
import { NavLink } from "../navlink";
import { NavigationSection } from "./components/navigation-section";
import { GameGeneratorIcon } from "../icons/game-generator-icon";
import { CustomGameIcon } from "../icons/custom-game-icon";
import { ScriptIcon } from "../icons/script-icon";
import { Anchor, Group } from "@mantine/core";
import { Link } from "react-router-dom";

export function Navigation() {
  return (
    <>
      <NavigationSection>
        <NavLink
          to="game-generator"
          label="Game Generator"
          leftSection={<GameGeneratorIcon />}
        />
        <NavLink
          to="game-builder"
          label="Custom Game Builder"
          leftSection={<CustomGameIcon />}
        />
      </NavigationSection>
      <NavigationSection title="You">
        <NavLink
          to="my-game-generators"
          label="Your Game Generators"
          leftSection={<GameGeneratorIcon />}
        />
        <NavLink
          to="my-games"
          label="Your Custom Games"
          leftSection={<CustomGameIcon />}
        />
        <NavLink
          to="my-scripts"
          label="Your Scripts"
          leftSection={<ScriptIcon />}
        />
      </NavigationSection>
      <NavigationSection grow title="Explore">
        <NavLink
          to="explore-game-generators"
          label="Game Generators"
          leftSection={<GameGeneratorIcon />}
        />
        <NavLink
          to="explore-games"
          label="Custom Games"
          leftSection={<CustomGameIcon />}
        />
        <NavLink
          to="explore-scripts"
          label="Scripts"
          leftSection={<ScriptIcon />}
        />
      </NavigationSection>
      {isDevelopmentMode() ? (
        <NavigationSection title="Dev Tools">
          <NavLink
            to="metronome"
            label="Metronome"
            leftSection={<IconActivity />}
          />
          <NavLink
            to="hud"
            label="HUD Testing"
            leftSection={<IconTestPipe />}
          />
        </NavigationSection>
      ) : null}
      <NavigationSection divider={false}>
        <NavLink
          to="subscription"
          label="Subscription"
          leftSection={<IconCoin />}
        />
        <NavLink
          to="settings"
          label="Settings"
          leftSection={<IconSettings />}
        />
        <Group gap="md" justify="center" mt="xs">
          <Anchor
            component={Link}
            to="/privacy-policy"
            target="_blank"
            size="xs"
          >
            Privacy
          </Anchor>
          <Anchor
            component={Link}
            to="/terms-of-service"
            target="_blank"
            size="xs"
          >
            Terms
          </Anchor>
          <Anchor href="mailto:support@fapinstructor.com" size="xs">
            Help
          </Anchor>
        </Group>
      </NavigationSection>
    </>
  );
}
