import { DelayedLoadingOverlay } from "@/components/delayed-component";
import * as React from "react";
import * as Router from "react-router-dom";

export default function FocusLayout() {
  return (
    <React.Suspense fallback={<DelayedLoadingOverlay />}>
      <Router.Outlet />
    </React.Suspense>
  );
}
