declare global {
  interface Window {
    Clerk?: {
      session?: {
        getToken(): Promise<string | null>;
      };
    };
  }
}

export async function getSessionToken() {
  if (!window.Clerk?.session) {
    return null;
  }

  const token = await window?.Clerk?.session?.getToken();
  return token;
}
