import type { ApiValidationError } from "@fapinstructor/common";
import { ZodError, type ZodIssue } from "zod";

export function deserializeZodErrorResponse(error: ApiValidationError) {
  const zodError = parseZodError(error);
  if (zodError) {
    const result = zodError.flatten();
    const errors = result.fieldErrors;
    return errors;
  }
}

function parseZodError(errorData: unknown): ZodError | null {
  if (
    typeof errorData === "object" &&
    errorData !== null &&
    "issues" in errorData &&
    Array.isArray(errorData.issues)
  ) {
    return new ZodError(errorData.issues as ZodIssue[]);
  }
  return null;
}
