import { ActionIcon, Button, Group, Image, Modal, Tabs } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { IconAccessPoint } from "@tabler/icons-react";
import { useSnapshot } from "valtio";
import { handyState } from "@/api/handy/handy";
import handySvg from "@/assets/handy.svg";
import lovensePng from "@/assets/lovense.png";
import autoblowPng from "@/assets/autoblow.png";
import { ConnectHandy } from "./connect-handy";
import { ConnectLovense } from "./connect-lovense";
import { useLovense } from "@/api/lovense/use-lovense";
import { supportedToys } from "@fapinstructor/lovense";
import { ConnectAutoblow } from "./connect-autoblow/connect-autoblow";
import { useAutoblow } from "@/api/autoblow/autoblow";

type ConnectDeviceModalProps = {
  size: "button" | "icon";
};

function useConnectedDeviceCount() {
  const { toys } = useLovense();
  const autoblow = useAutoblow();
  const { current: handyStateSnap } = useSnapshot(handyState);
  const handyConnected = Boolean(handyStateSnap?.connected);

  let count = 0;
  if (toys.length) {
    count += toys.filter(
      (toy) =>
        toy.connected && (supportedToys as string[]).includes(toy.toyType),
    ).length;
  }

  if (handyConnected) {
    count += 1;
  }

  if (autoblow.status === "connected") {
    count += 1;
  }

  return count;
}

export function ConnectDeviceModal({ size }: ConnectDeviceModalProps) {
  const [opened, { open, close }] = useDisclosure(false);
  const connectedDeviceCount = useConnectedDeviceCount();
  const matches = useMediaQuery("(min-width: 800px)");
  useLovense();

  let action: JSX.Element;

  if (size === "icon" || !matches) {
    action = (
      <ActionIcon
        title={
          connectedDeviceCount > 0 ? "Connected devices" : "Connect devices"
        }
        variant="subtle"
        onClick={open}
        color={connectedDeviceCount > 0 ? "green" : "gray"}
      >
        <IconAccessPoint />
      </ActionIcon>
    );
  } else {
    action = (
      <Button
        color={connectedDeviceCount > 0 ? "green" : "pink"}
        variant="light"
        onClick={open}
      >
        <Group gap="0.25rem">
          <IconAccessPoint />
          {connectedDeviceCount > 0
            ? `Connected Devices (${connectedDeviceCount})`
            : "Connect Devices"}
        </Group>
      </Button>
    );
  }

  return (
    <>
      <Modal opened={opened} onClose={close} title="Connect Devices">
        <DeviceTabs />
      </Modal>
      {action}
    </>
  );
}

export function DeviceTabs() {
  return (
    <Tabs defaultValue="thehandy">
      <Tabs.List mb="md">
        <Tabs.Tab
          value="thehandy"
          leftSection={<Image h={25} src={handySvg} />}
        >
          TheHandy
        </Tabs.Tab>
        <Tabs.Tab
          value="lovense"
          leftSection={<Image h={25} src={lovensePng} />}
        >
          Lovense
        </Tabs.Tab>
        <Tabs.Tab
          value="autoblow"
          leftSection={<Image h={25} src={autoblowPng} />}
        >
          Autoblow
        </Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value="thehandy">
        <ConnectHandy />
      </Tabs.Panel>
      <Tabs.Panel value="lovense">
        <ConnectLovense />
      </Tabs.Panel>
      <Tabs.Panel value="autoblow">
        <ConnectAutoblow />
      </Tabs.Panel>
    </Tabs>
  );
}
