import * as React from "react";
import { queryClient } from "@/providers/query-client-provider";
import { useAuth } from "@clerk/clerk-react";

/**
 * When the user signs out bust the react-query cache.
 */
export function AuthListener() {
  const { isSignedIn } = useAuth();

  React.useEffect(() => {
    if (!isSignedIn) {
      queryClient.clear();
    }
  }, [isSignedIn]);

  return null;
}
