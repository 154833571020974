import { isPatreonSubscriberResponse } from "@fapinstructor/common";
import { useQuery } from "@tanstack/react-query";
import { fapinstructorApi } from "../../api";
import { useAuth } from "@clerk/clerk-react";

export const GET_PATREON_STATUS_QUERY_KEY = ["patreon", "status"];

async function getPatreonStatus() {
  const patreonStatus = await fapinstructorApi
    .get("/patreon/status")
    .json(isPatreonSubscriberResponse);
  return patreonStatus;
}

export function usePatronStatus() {
  const { isSignedIn } = useAuth();

  const query = useQuery({
    queryKey: GET_PATREON_STATUS_QUERY_KEY,
    queryFn: getPatreonStatus,
    staleTime: PATREON_STATUS_STALE_TIME,
    enabled: isSignedIn,
  });

  return query;
}

const PATREON_STATUS_STALE_TIME =
  20 * // min
  60 * // sec
  1000; // ms
