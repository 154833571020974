import * as Handy from "@ohdoki/handy-sdk";
import { proxy } from "valtio";
import { Queue } from "@/utils/queue";
import getStrokeZoneAndVelocity from "./utils/get-stroke-speed-and-distance";
import { handySettings } from "./store/handy-settings";
import type { StrokeStyle } from "@fapinstructor/common";

// Max stroke is around 200mm including buffers at the end. Varies +-5mm depending on FW and batch #.
const MAX_HANDY_DEVICE_STROKE_LENGTH = 195;

export const handyTempoQueue = new Queue();

export async function setHandyTempo(tempo: number, style: StrokeStyle) {
  if (tempo === 0) {
    handyTempoQueue.clear();
    _setHandyTempo(tempo, style);
  } else {
    await handyTempoQueue.enqueue(() => _setHandyTempo(tempo, style));
  }
}

export function adjustSlideByStrokeStyle(
  [min, max]: [min: number, max: number],
  strokeStyle: StrokeStyle,
): [min: number, max: number] {
  switch (strokeStyle) {
    case "head": {
      // calculate the distance between min and max.  Then chop of 80% min.
      const distance = max - min;
      const reducedMin = min + distance * 0.8;
      return [reducedMin, max];
    }
    case "shaft": {
      // Calculate the distance between min and max.  Then chop of 60% from max.
      const distance = max - min;
      const reducedMax = max - distance * 0.6;
      return [min, reducedMax];
    }
    default:
      return [min, max];
  }
}

async function _setHandyTempo(tempo: number, strokeStyle: StrokeStyle) {
  const slideSetting = adjustSlideByStrokeStyle(
    handySettings.slide,
    strokeStyle,
  );
  const { velocity, strokeZone } = getStrokeZoneAndVelocity(
    tempo,
    slideSetting,
    MAX_HANDY_DEVICE_STROKE_LENGTH,
  );
  const { slide, hamp } = handy.getState();
  const calls: Promise<unknown>[] = [];

  if (velocity !== hamp?.velocity) {
    if (velocity === 0 && hamp?.state === Handy.HAMPState.MOVING) {
      await handy.hampStop();
    } else if (velocity > 0) {
      if (hamp?.state !== Handy.HAMPState.MOVING) {
        await handy.hampPlay();
      }
      calls.push(handy.setHampVelocity(velocity));
    }
  }

  if (slide?.min !== strokeZone.min || slide?.max !== strokeZone.max) {
    calls.push(handy.setStrokeZone(strokeZone));
  }

  await Promise.all(calls);
}

export const handy = Handy.init({
  throttleDelay: 0,
});
reconnect();

export async function connectHandy(connectionKey: string) {
  const connectResult = await handy.connect(connectionKey);
  if (connectResult === Handy.ConnectResult.CONNECTED) {
    await handy.setMode(Handy.Mode.HAMP);
  }
}

export async function reconnect() {
  const connectionKey = await handy.getStoredKey();
  if (!connectionKey) return;

  const connectResult = await handy.connect(connectionKey);
  if (connectResult === Handy.ConnectResult.CONNECTED) {
    await handy.setMode(Handy.Mode.HAMP);

    if (handySettings.slide) {
      const [min, max] = handySettings.slide;
      handy.setStrokeZone({ min, max });
    }
  }
}

export const handyState: {
  current?: Handy.HandyState;
} = proxy({
  current: undefined,
});
handy.on("state", ({ state }) => {
  handyState.current = state;
});
