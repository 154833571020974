import z from "zod";
import { cockBallsBindingStylesSchema, NippleStimulateStyleSchema, strokeGripSchema, strokeStyleSchema, } from "./tasks/index.js";
import { mediaConfigSchema } from "./game.js";
import { VoiceSchema } from "./script/speech.js";
import { SCRIPT_ID } from "./script/script.js";
export const settingsMode = z.enum(["minimal", "advanced", "expert"]);
export function isSettingEnabled(setting, required) {
    const settingsModeIndex = settingsMode.options.findIndex((mode) => mode === setting);
    const requiredModeIndex = settingsMode.options.findIndex((mode) => mode === required);
    return settingsModeIndex >= requiredModeIndex;
}
const TaskBase = z.object({
    enabled: z.boolean(),
});
const Task = TaskBase.extend({
    weight: z.number().min(0).max(1),
});
const CEITargets = Task.extend({
    face: Task,
    mouth: Task,
    hand: Task,
    floor: Task,
    chest: Task,
    cup: Task,
});
export const strokeHandTasks = Task.extend({
    dominant: Task,
    "non-dominant": Task,
});
const InstructorSchema = z.object({
    voice: VoiceSchema.nullable(),
    script: z.string().uuid().nullable(),
    introduction: z.boolean(),
    moans: z.boolean(),
});
export const gameGeneratorFormSchema = z.object({
    version: z.number(),
    media: mediaConfigSchema,
    instructor: InstructorSchema,
    settingsMode,
    gameDuration: z.tuple([z.number().min(0), z.number().min(1)]),
    taskFrequency: z.tuple([z.number().min(0), z.number().min(1)]),
    cock: Task.extend({
        warmup: TaskBase.extend({
            duration: z.number(),
        }),
        cei: Task.extend({
            precum: Task,
        }),
        lube: Task.extend({
            lube: Task,
            spit: Task,
        }),
        cbt: Task.extend({
            squeezeBalls: Task.extend({
                duration: z.tuple([z.number().min(0), z.number().min(0)]),
            }),
            slapBalls: Task.extend({
                slaps: z.tuple([z.number().min(0), z.number().min(0)]),
            }),
            flickBalls: Task.extend({
                flicks: z.tuple([z.number().min(0), z.number().min(0)]),
            }),
            flickCock: Task.extend({
                flicks: z.tuple([z.number().min(0), z.number().min(0)]),
            }),
            palming: Task.extend({
                tempoPercent: z.tuple([z.number().min(0), z.number().min(0)]),
                duration: z.tuple([z.number().min(0), z.number().min(0)]),
            }),
            bindCockBalls: Task.extend({
                options: z.array(cockBallsBindingStylesSchema),
            }),
            icyHot: Task,
            toothpaste: Task,
            rubberBands: Task.extend({
                attach: Task.extend({
                    limit: z.number(),
                }),
                detach: Task,
                snap: Task.extend({
                    snaps: z.tuple([z.number().min(0), z.number().min(0)]),
                }),
            }),
        }),
        stroke: Task.extend({
            tempo: Task.extend({
                standardDeviation: z.number(),
            }),
            grip: Task.extend({
                options: z.array(strokeGripSchema),
            }),
            style: Task.extend({
                options: z.array(strokeStyleSchema),
            }),
            hand: strokeHandTasks,
            clench: Task.extend({
                duration: z.tuple([z.number().min(0), z.number().min(0)]),
            }),
            doubleSpeed: Task.extend({
                duration: z.tuple([z.number().min(0), z.number().min(0)]),
            }),
            gripChallenge: Task.extend({
                cycles: z.tuple([z.number().min(0), z.number().min(0)]),
                lightGripDuration: z.tuple([z.number().min(0), z.number().min(0)]),
                tightGripDuration: z.tuple([z.number().min(0), z.number().min(0)]),
            }),
            teasingStrokes: Task.extend({
                duration: z.tuple([z.number().min(0), z.number().min(0)]),
            }),
            acceleration: Task.extend({
                duration: z.tuple([z.number().min(0), z.number().min(0)]),
            }),
            clusterStrokes: Task.extend({
                sets: z.tuple([z.number().min(0), z.number().min(0)]),
                reps: z.tuple([z.number().min(0), z.number().min(0)]),
            }),
            redLightGreenLight: Task.extend({
                cycles: z.tuple([z.number().min(0), z.number().min(0)]),
                greenLightDuration: z.tuple([z.number().min(0), z.number().min(0)]),
                redLightDuration: z.tuple([z.number().min(0), z.number().min(0)]),
            }),
        }),
    }),
    nipples: Task.extend({
        stimulate: Task.extend({
            options: z.array(NippleStimulateStyleSchema),
            duration: z.tuple([z.number().min(0), z.number().min(0)]),
        }),
        flicking: Task.extend({
            flicks: z.tuple([z.number().min(0), z.number().min(0)]),
        }),
        clamps: Task.extend({
            attach: Task,
            detach: Task,
            ripOff: Task,
            pinch: Task.extend({
                duration: z.tuple([z.number().min(0), z.number().min(0)]),
            }),
            clampWeight: Task.extend({
                weightLimit: z.number(), // grams
                weightIncrement: z.number(), // grams
                add: Task,
                remove: Task,
            }),
            twist: Task.extend({
                duration: z.tuple([z.number().min(0), z.number().min(0)]),
            }),
            pull: Task.extend({
                duration: z.tuple([z.number().min(0), z.number().min(0)]),
            }),
            shake: Task.extend({
                duration: z.tuple([z.number().min(0), z.number().min(0)]),
            }),
        }),
    }),
    eyes: Task.extend({
        close: Task.extend({
            duration: z.tuple([z.number().min(0), z.number().min(0)]),
        }),
    }),
    orgasm: Task.extend({
        edging: Task.extend({
            edges: z.tuple([z.number().min(0), z.number().min(0)]),
            duration: z.tuple([z.number().min(0), z.number().min(0)]),
            cooldown: z.tuple([z.number().min(0), z.number().min(0)]),
            gap: z.number(),
            countdown: z.tuple([z.number().min(0), z.number().min(0)]),
        }),
        cum: Task.extend({
            cei: CEITargets,
            orgasms: z.tuple([z.number().min(0), z.number().min(0)]),
            cooldown: z.tuple([z.number().min(0), z.number().min(0)]),
            gap: z.number(),
            postOrgasmTorture: TaskBase.extend({
                duration: z.tuple([z.number().min(0), z.number().min(0)]),
                stroking: Task,
                palming: Task,
            }),
        }),
        ruined: Task.extend({
            cei: CEITargets,
            ruins: z.tuple([z.number().min(0), z.number().min(0)]),
            cooldown: z.tuple([z.number().min(0), z.number().min(0)]),
            gap: z.number(),
        }),
        denial: Task,
    }),
    breathPlay: Task.extend({
        holdBreath: Task.extend({
            duration: z.tuple([z.number().min(0), z.number().min(0)]),
        }),
    }),
    anal: Task.extend({
        buttPlug: Task.extend({
            insert: Task,
            remove: Task,
            vibrator: Task.extend({
                increaseStep: z.tuple([z.number().min(0), z.number().min(0)]),
                decreaseStep: z.tuple([z.number().min(0), z.number().min(0)]),
            }),
            inflatable: Task.extend({
                inflate: Task.extend({
                    pumps: z.number(),
                    step: z.tuple([z.number().min(0), z.number().min(0)]),
                }),
                deflate: Task,
                insertWhileInflated: Task.extend({
                    min: z.number(),
                    max: z.number(),
                }),
                removeWhileInflated: Task.extend({
                    min: z.number(),
                    max: z.number(),
                }),
            }),
        }),
    }),
});
export const gameGeneratorFormInitialValues = {
    version: 10,
    media: {
        tags: ["nsfw"],
        mediaTypes: ["video"],
    },
    instructor: {
        voice: "chloe",
        script: SCRIPT_ID.DEFAULT,
        introduction: true,
        moans: true,
    },
    settingsMode: "minimal",
    gameDuration: [5, 10],
    taskFrequency: [5, 15],
    cock: {
        enabled: true,
        weight: 1,
        warmup: {
            enabled: true,
            duration: 30,
        },
        cei: {
            enabled: false,
            weight: 1,
            precum: {
                enabled: true,
                weight: 1,
            },
        },
        lube: {
            enabled: false,
            weight: 1,
            lube: {
                enabled: true,
                weight: 1,
            },
            spit: {
                enabled: false,
                weight: 1,
            },
        },
        cbt: {
            enabled: false,
            weight: 1,
            squeezeBalls: {
                enabled: true,
                weight: 1,
                duration: [5, 15],
            },
            slapBalls: {
                enabled: true,
                weight: 0.4,
                slaps: [5, 15],
            },
            flickBalls: {
                enabled: true,
                weight: 0.4,
                flicks: [5, 15],
            },
            flickCock: {
                enabled: true,
                weight: 0.4,
                flicks: [5, 15],
            },
            palming: {
                enabled: true,
                weight: 0.5,
                tempoPercent: [10, 75],
                duration: [5, 15],
            },
            bindCockBalls: {
                enabled: false,
                weight: 0.2,
                options: [
                    "ball-wrapping",
                    "cock-balls-tie",
                    "ball-seperation",
                    "cock-ball-wrapping",
                ],
            },
            icyHot: {
                enabled: false,
                weight: 0.2,
            },
            toothpaste: {
                enabled: false,
                weight: 0.2,
            },
            rubberBands: {
                enabled: false,
                weight: 0.2,
                attach: {
                    enabled: true,
                    weight: 1,
                    limit: 5,
                },
                detach: {
                    enabled: true,
                    weight: 0.2,
                },
                snap: {
                    enabled: true,
                    weight: 1,
                    snaps: [3, 7],
                },
            },
        },
        stroke: {
            enabled: true,
            weight: 1,
            tempo: {
                enabled: true,
                weight: 1,
                standardDeviation: 0.5,
            },
            grip: {
                enabled: true,
                weight: 0.5,
                options: ["light", "normal", "tight"],
            },
            style: {
                enabled: true,
                weight: 0.3,
                options: ["full", "head", "shaft"],
            },
            hand: {
                enabled: true,
                weight: 0.3,
                dominant: {
                    enabled: true,
                    weight: 1,
                },
                "non-dominant": {
                    enabled: false,
                    weight: 0.3,
                },
            },
            clench: {
                enabled: true,
                weight: 0.5,
                duration: [10, 30],
            },
            doubleSpeed: {
                enabled: true,
                weight: 0.2,
                duration: [5, 15],
            },
            gripChallenge: {
                enabled: true,
                weight: 0.2,
                cycles: [3, 10],
                lightGripDuration: [5, 10],
                tightGripDuration: [5, 15],
            },
            teasingStrokes: {
                enabled: true,
                weight: 0.2,
                duration: [20, 60],
            },
            acceleration: {
                enabled: true,
                weight: 0.2,
                duration: [10, 60],
            },
            clusterStrokes: {
                enabled: true,
                weight: 0.1,
                sets: [3, 6],
                reps: [3, 15],
            },
            redLightGreenLight: {
                enabled: true,
                weight: 0.1,
                cycles: [3, 7],
                greenLightDuration: [3, 10],
                redLightDuration: [3, 15],
            },
        },
    },
    nipples: {
        enabled: true,
        weight: 0.3,
        stimulate: {
            enabled: false,
            weight: 0.2,
            duration: [5, 10],
            options: ["circle", "up-and-down", "side-to-side"],
        },
        flicking: {
            enabled: false,
            weight: 0.2,
            flicks: [5, 15],
        },
        clamps: {
            enabled: false,
            weight: 0.2,
            attach: {
                enabled: true,
                weight: 1,
            },
            detach: {
                enabled: true,
                weight: 0.2,
            },
            ripOff: {
                enabled: true,
                weight: 0.2,
            },
            pinch: {
                enabled: true,
                weight: 1,
                duration: [5, 10],
            },
            clampWeight: {
                enabled: true,
                weight: 1,
                weightLimit: 500,
                weightIncrement: 100,
                add: {
                    enabled: true,
                    weight: 1,
                },
                remove: {
                    enabled: true,
                    weight: 0.5,
                },
            },
            twist: {
                enabled: true,
                weight: 1,
                duration: [5, 15],
            },
            pull: {
                enabled: true,
                weight: 1,
                duration: [5, 15],
            },
            shake: {
                enabled: true,
                weight: 1,
                duration: [5, 15],
            },
        },
    },
    eyes: {
        enabled: true,
        weight: 0.3,
        close: {
            enabled: false,
            weight: 1,
            duration: [20, 40],
        },
    },
    orgasm: {
        enabled: true,
        weight: 0.1,
        edging: {
            enabled: true,
            weight: 1,
            edges: [0, 2],
            duration: [5, 15],
            cooldown: [5, 10],
            gap: 30,
            countdown: [10, 10],
        },
        cum: {
            enabled: true,
            // Used for the finale, during game play this is dynamically calculated.
            weight: 1,
            cei: {
                enabled: false,
                weight: 1,
                face: {
                    enabled: true,
                    weight: 1,
                },
                mouth: {
                    enabled: true,
                    weight: 1,
                },
                hand: {
                    enabled: true,
                    weight: 1,
                },
                floor: {
                    enabled: true,
                    weight: 1,
                },
                chest: {
                    enabled: true,
                    weight: 1,
                },
                cup: {
                    enabled: true,
                    weight: 0,
                },
            },
            orgasms: [1, 1],
            cooldown: [60, 120],
            gap: 60,
            postOrgasmTorture: {
                enabled: false,
                duration: [10, 60],
                stroking: {
                    enabled: true,
                    weight: 1,
                },
                palming: {
                    enabled: false,
                    weight: 1,
                },
            },
        },
        ruined: {
            enabled: false,
            // Used for the finale, during game play this is dynamically calculated.
            weight: 1,
            cei: {
                enabled: false,
                weight: 1,
                face: {
                    enabled: true,
                    weight: 1,
                },
                mouth: {
                    enabled: true,
                    weight: 1,
                },
                hand: {
                    enabled: true,
                    weight: 1,
                },
                floor: {
                    enabled: true,
                    weight: 1,
                },
                chest: {
                    enabled: true,
                    weight: 1,
                },
                cup: {
                    enabled: true,
                    weight: 0,
                },
            },
            ruins: [0, 3],
            cooldown: [20, 40],
            gap: 60,
        },
        denial: {
            enabled: false,
            weight: 1,
        },
    },
    breathPlay: {
        enabled: true,
        weight: 1,
        holdBreath: {
            enabled: false,
            weight: 1,
            duration: [10, 60],
        },
    },
    anal: {
        enabled: true,
        weight: 1,
        buttPlug: {
            enabled: false,
            weight: 1,
            insert: {
                enabled: true,
                weight: 1,
            },
            remove: {
                enabled: true,
                weight: 1,
            },
            vibrator: {
                enabled: false,
                weight: 1,
                increaseStep: [10, 30],
                decreaseStep: [5, 15],
            },
            inflatable: {
                enabled: false,
                weight: 1,
                insertWhileInflated: {
                    enabled: true,
                    weight: 0.1,
                    min: 1,
                    max: 5,
                },
                removeWhileInflated: {
                    enabled: true,
                    weight: 0.1,
                    min: 1,
                    max: 5,
                },
                inflate: {
                    enabled: true,
                    weight: 1,
                    pumps: 10,
                    step: [1, 3],
                },
                deflate: {
                    enabled: true,
                    weight: 0.5,
                },
            },
        },
    },
};
export function migrateGameConfig(form) {
    if (form.version < 0.9) {
        {
            const { floor, chest, cup } = gameGeneratorFormInitialValues.orgasm.cum.cei;
            form.orgasm.cum.cei.floor = floor;
            form.orgasm.cum.cei.chest = chest;
            form.orgasm.cum.cei.cup = cup;
        }
        {
            const { floor, chest, cup } = gameGeneratorFormInitialValues.orgasm.ruined.cei;
            form.orgasm.ruined.cei.floor = floor;
            form.orgasm.ruined.cei.chest = chest;
            form.orgasm.ruined.cei.cup = cup;
        }
        form.version = 0.9;
    }
    if (form.version < 1) {
        form.instructor = gameGeneratorFormInitialValues.instructor;
        form.version = 1;
    }
    if (form.version < 1.01) {
        form.anal.buttPlug.vibrator =
            gameGeneratorFormInitialValues.anal.buttPlug.vibrator;
        form.version = 1.01;
    }
    if (form.version < 1.02) {
        form.orgasm = gameGeneratorFormInitialValues.orgasm;
        form.version = 1.02;
    }
    if (form.version < 1.03) {
        form.anal.buttPlug.inflatable.inflate =
            gameGeneratorFormInitialValues.anal.buttPlug.inflatable.inflate;
        form.anal.buttPlug.inflatable.insertWhileInflated =
            gameGeneratorFormInitialValues.anal.buttPlug.inflatable.insertWhileInflated;
        form.anal.buttPlug.inflatable.removeWhileInflated =
            gameGeneratorFormInitialValues.anal.buttPlug.inflatable.removeWhileInflated;
        form.version = 1.03;
    }
    if (form.version < 2) {
        form.orgasm.cum.postOrgasmTorture.stroking =
            gameGeneratorFormInitialValues.orgasm.cum.postOrgasmTorture.stroking;
        form.orgasm.cum.postOrgasmTorture.palming =
            gameGeneratorFormInitialValues.orgasm.cum.postOrgasmTorture.palming;
        form.version = 2;
    }
    if (form.version < 3) {
        form.nipples.enabled = gameGeneratorFormInitialValues.nipples.enabled;
        form.version = 3;
    }
    if (form.version < 4) {
        form.eyes = gameGeneratorFormInitialValues.eyes;
        form.version = 4;
    }
    if (form.version < 5) {
        form.cock.lube = gameGeneratorFormInitialValues.cock.lube;
        form.version = 5;
    }
    if (form.version < 6) {
        form.anal.buttPlug.vibrator =
            gameGeneratorFormInitialValues.anal.buttPlug.vibrator;
        form.version = 6;
    }
    if (form.version < 7) {
        form.cock.stroke.hand = gameGeneratorFormInitialValues.cock.stroke.hand;
        form.version = 7;
    }
    if (form.version < 8) {
        form.instructor.script = gameGeneratorFormInitialValues.instructor.script;
        form.version = 8;
    }
    if (form.version < 9) {
        form.nipples.clamps.pull =
            gameGeneratorFormInitialValues.nipples.clamps.pull;
        form.nipples.clamps.shake =
            gameGeneratorFormInitialValues.nipples.clamps.shake;
        form.version = 9;
    }
    if (form.version < 10) {
        form.nipples.stimulate = gameGeneratorFormInitialValues.nipples.stimulate;
        form.nipples.flicking = gameGeneratorFormInitialValues.nipples.flicking;
        form.version = 10;
    }
    return form;
}
