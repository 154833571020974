import { createSearchParams, } from "./api/create-search-params.js";
import { ApiError } from "./api/api-error.js";
import { ApiResponseHandler } from "./api/api-response-handler.js";
import { createApiUrl } from "./api/create-api-url.js";
export class ApiClient {
    baseUrl;
    beforeRequest = [];
    afterRequest = [];
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }
    static request(url, method, options = {}) {
        const { params, beforeRequest, afterRequest } = options;
        const executeRequest = async () => {
            // Append query params to url.
            if (params) {
                url.search = createSearchParams(params).toString();
            }
            const headers = new Headers(options.headers);
            // Configure content headers and body.
            let body;
            if (options.body instanceof URLSearchParams) {
                headers.set("Content-Type", "application/x-www-form-urlencoded");
                body = options.body;
            }
            else if (options.body) {
                headers.set("Content-Type", "application/json");
                body = JSON.stringify(options.body) ?? "";
            }
            const request = new Request(url, { method, headers, body });
            // Run beforeRequest middleware.
            if (beforeRequest) {
                await Promise.all(beforeRequest.map((fn) => fn(request)));
            }
            const response = await fetch(request);
            if (!response.ok) {
                throw new ApiError(request, response);
            }
            // Run afterRequest middleware.
            if (afterRequest) {
                await Promise.all(afterRequest.map((fn) => fn(response)));
            }
            return response;
        };
        return new ApiResponseHandler(executeRequest());
    }
    createRequest(endpoint, method, options) {
        const url = createApiUrl(endpoint, this.baseUrl);
        return ApiClient.request(url, method, {
            ...options,
            beforeRequest: this.beforeRequest,
        });
    }
    static createRequest(url, method, options) {
        return ApiClient.request(createApiUrl(url), method, options);
    }
    static get(url, options) {
        return ApiClient.createRequest(url, "GET", options);
    }
    static post(url, options) {
        return ApiClient.createRequest(url, "POST", options);
    }
    static put(url, options) {
        return ApiClient.createRequest(url, "PUT", options);
    }
    static patch(url, options) {
        return ApiClient.createRequest(url, "PATCH", options);
    }
    static head(url, options) {
        return ApiClient.createRequest(url, "HEAD", options).noContent();
    }
    static delete(url, options) {
        return ApiClient.createRequest(url, "DELETE", options);
    }
    get(endpoint, options) {
        return this.createRequest(endpoint, "GET", options);
    }
    post(endpoint, options) {
        return this.createRequest(endpoint, "POST", options);
    }
    put(endpoint, options) {
        return this.createRequest(endpoint, "PUT", options);
    }
    patch(endpoint, options) {
        return this.createRequest(endpoint, "PATCH", options);
    }
    delete(endpoint, options) {
        return this.createRequest(endpoint, "DELETE", options);
    }
    head(endpoint, options) {
        return this.createRequest(endpoint, "HEAD", options).noContent();
    }
}
