import {
  ActionIcon,
  Anchor,
  Card,
  Center,
  CopyButton,
  Group,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconCopy } from "@tabler/icons-react";
import type * as React from "react";

type ErrorCardProps = {
  title: React.ReactNode;
  message: string;
};

export function ErrorCard({ title, message }: ErrorCardProps) {
  return (
    <Stack ta="center" gap="sm">
      <Title>{title}</Title>
      <Center>
        <Card withBorder>
          <Group>
            <Text size="lg" c="red" maw={700}>
              {message}
            </Text>
            <CopyButton value={message}>
              {({ copy }) => (
                <ActionIcon
                  title="Copy error"
                  variant="light"
                  size="lg"
                  onClick={() => {
                    notifications.show({
                      title: "Copied to clipboard",
                      message:
                        "Successfully copied the error to your clipboard.",
                      color: "green",
                      autoClose: 3000,
                    });
                    copy();
                  }}
                >
                  <IconCopy />
                </ActionIcon>
              )}
            </CopyButton>
          </Group>
        </Card>
      </Center>
      <Center>
        <Text maw={500}>
          Please open a support ticket by emailing us at{" "}
          <Anchor inherit href="mailto:support@fapinstructor.com">
            support@fapinstructor.com
          </Anchor>{" "}
          with the above error to help us resolve this issue.
        </Text>
      </Center>
    </Stack>
  );
}
