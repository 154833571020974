import { FAPINSTRUCTOR_API, LANGUAGETOOL_API, SCROLLLER_API } from "@/config";
import {
  ApiClient,
  type ApiError,
  ApiValidationErrorSchema,
} from "@fapinstructor/common";
import { getSessionToken } from "@/utils/get-session-token";
import { deserializeZodErrorResponse } from "@/utils/deserialize-zod-error-response";

export const fapinstructorApi = new ApiClient(FAPINSTRUCTOR_API);

// Automatically attach session token.
fapinstructorApi.beforeRequest.push(async (request) => {
  const token = await getSessionToken();
  if (token) {
    request.headers.set("Authorization", `Bearer ${token}`);
  }
});

export const scrolllerApi = new ApiClient(SCROLLLER_API);

export const languageToolApi = new ApiClient(LANGUAGETOOL_API);

export async function handleApiError(error: ApiError) {
  if (error.response.status === 400) {
    try {
      const data = await error.response.json();
      const validationError = ApiValidationErrorSchema.parse(data);
      const errors = deserializeZodErrorResponse(validationError);

      if (!errors) {
        throw error;
      }
      return errors;
    } catch {
      throw error;
    }
  }
  throw error;
}
