import * as Sentry from "@sentry/react";
import "@mantine/core/styles.css";
import { notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import * as ReactQuery from "@tanstack/react-query";
import { QueryCache } from "@tanstack/react-query";
import type * as React from "react";
import { ApiError } from "@fapinstructor/common";

export const queryClient = new ReactQuery.QueryClient({
  defaultOptions: {
    queries: {
      retry(failureCount, error) {
        if (error instanceof ApiError && error.status === 404) {
          return false;
        }
        return failureCount < 5;
      },
    },
  },
  queryCache: new QueryCache({
    onError(error, query) {
      Sentry.captureException(error);

      if (query.meta?.errorDescription) {
        notifications.show({
          title: error.message,
          message: query.meta.errorDescription as string,
          color: "red",
          autoClose: 10000,
        });
      }
    },
  }),
});

type QueryProviderProps = { children: React.ReactNode };

export function QueryClientProvider({ children }: QueryProviderProps) {
  return (
    <ReactQuery.QueryClientProvider client={queryClient}>
      {children}
    </ReactQuery.QueryClientProvider>
  );
}
