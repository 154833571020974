import { GetLovenseTokenSchema } from "@fapinstructor/common";
import { fapinstructorApi } from "../../api";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "@clerk/clerk-react";

async function getLovenseToken() {
  const { data } = await fapinstructorApi
    .get("/lovense/token")
    .json(GetLovenseTokenSchema);

  if (!data?.authToken) {
    throw new Error("Failed to retrieve lovense token.");
  }

  return data.authToken;
}

export function useLovenseToken() {
  const { isSignedIn } = useAuth();
  const query = useQuery({
    queryKey: ["lovense", "token"],
    queryFn: getLovenseToken,
    enabled: isSignedIn,
  });

  return query;
}
