import * as React from "react";
import { Button, Stack, Text, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import autoblowImage from "@/assets/autoblow.png";
import { CompanyTitle as DeviceCompanyTitle } from "../components/device-company-title";
import { BuyAutoblowButton } from "./components/buy-autoblow-button";
import {
  AutoblowConfigSchema,
  autoblow,
  useAutoblow,
} from "@/api/autoblow/autoblow";
import { getErrorMessage } from "@/utils/get-error-message";

export function ConnectAutoblow() {
  const { status, error, deviceToken } = useAutoblow();

  const form = useForm({
    validate: zodResolver(AutoblowConfigSchema),
    initialValues: {
      deviceToken,
    },
  });

  React.useEffect(() => {
    if (!form.isDirty() && form.values.deviceToken !== deviceToken) {
      form.setValues({
        deviceToken,
      });
    }
  }, [form, deviceToken]);

  return (
    <form
      onSubmit={form.onSubmit(async ({ deviceToken }) => {
        if (status === "connected") {
          autoblow.disconnect();
          form.setValues({
            deviceToken: "",
          });
        } else {
          autoblow.connect(deviceToken);
        }
      })}
    >
      <Stack>
        <DeviceCompanyTitle name="Autoblow" logo={autoblowImage} />
        {status !== "connected" ? <BuyAutoblowButton /> : null}
        {status === "disconnected" ? <DisconnectedStatus /> : null}
        {status === "connected" ? <ConnectedStatus /> : null}
        {error ? <ErrorStatus error={error} /> : null}
        <TextInput
          placeholder="Enter device token"
          {...form.getInputProps("deviceToken")}
        />
        <Button loading={status === "connecting"} type="submit">
          {status === "connected" ? "Disconnect" : "Connect"}
        </Button>
      </Stack>
    </form>
  );
}

type ErrorProps = { error: unknown };

function ErrorStatus({ error }: ErrorProps) {
  const message = getErrorMessage(error);

  return <Text c="red">{message}</Text>;
}

function ConnectedStatus() {
  return <Text c="green">Device connected</Text>;
}

function DisconnectedStatus() {
  return <Text c="orange">Autoblow is not connected.</Text>;
}
