import { z } from "zod";
import { ScriptTriggerSchema } from "./script-triggers.js";
export const MAX_DIALOG_LENGTH = 500;
export const DialogSchema = z.object({
    id: z.string().uuid(),
    trigger: ScriptTriggerSchema,
    text: z.string().min(1).max(MAX_DIALOG_LENGTH),
    speech: z.string().optional(),
    createdBy: z.object({
        id: z.string(),
        username: z.string(),
    }),
    cloned: z.boolean(),
    published: z.boolean(),
});
export const CreateDialogSchema = DialogSchema.pick({
    trigger: true,
    text: true,
    cloned: true,
    published: true,
});
export const UpdateDialogSchema = DialogSchema.pick({
    text: true,
});
