import { accountSettingsSchema } from "@fapinstructor/common";
import { useQuery } from "@tanstack/react-query";
import { fapinstructorApi } from "../../api";
import { useAuth } from "@clerk/clerk-react";

async function getAccountSettings() {
  return await fapinstructorApi
    .get("/account/settings")
    .json(accountSettingsSchema);
}

export function useAccountSettings() {
  const { isSignedIn } = useAuth();

  const query = useQuery({
    queryKey: ["account", "settings"],
    queryFn: getAccountSettings,
    staleTime: 60 * 60 * 1000, // 1 hour
    throwOnError: true,
    enabled: isSignedIn,
  });

  return query;
}
