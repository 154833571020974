export class ApiError extends Error {
    status;
    response;
    request;
    constructor(request, response) {
        const code = response.status || response.status === 0 ? response.status : "";
        const title = response.statusText || "";
        const status = `${code} ${title}`.trim();
        const reason = status ? `status code ${status}` : "an unknown error";
        super(`Request failed with ${reason}: ${request.method} ${request.url}`);
        this.name = "ApiError";
        this.status = response.status;
        this.response = response;
        this.request = request;
    }
}
