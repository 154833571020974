import z from "zod";
export const mediaTypeFilterSchema = z.enum(["image", "video"]);
export const mediaTypesSchema = z.enum(["embed", "video", "image"]);
export const mediaSourcesSchema = z.enum(["scrolller", "local"]);
export const mediaLinkSchema = z.object({
    mediaType: mediaTypesSchema,
    tag: z.string(),
    source: z.object({ id: mediaSourcesSchema, url: z.string() }),
    url: z.string(),
    duration: z.number().optional(),
});
export const mediaResponseSchema = z.object({
    links: z.array(mediaLinkSchema),
});
const MediaTagSchema = z.object({
    title: z.string(),
    description: z.string(),
});
export const MediaTagSearchResponseSchema = z.array(MediaTagSchema);
