import { Flex, Loader, LoadingOverlay } from "@mantine/core";
import * as React from "react";

type DelayedComponentProps = {
  children: React.ReactNode;
  delay: number;
};

/**
 * Render the component after the specified delay (ms).
 */
export function DelayedComponent({ children, delay }: DelayedComponentProps) {
  const [done, setDone] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setDone(true);
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return done ? children : null;
}

export function DelayedLoadingOverlay() {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  if (!show) {
    return null;
  }

  return (
    <LoadingOverlay
      visible={true}
      zIndex={1000}
      loaderProps={{ color: "pink", type: "bars" }}
    />
  );
}

export function DelayedLoader() {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  if (!show) {
    return null;
  }

  return (
    <Flex justify="center" align="center" pt="10svh">
      <Loader color="pink" />
    </Flex>
  );
}
