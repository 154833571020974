import { z } from "zod";
export const ScriptTriggerSchema = z.enum([
    "introduction",
    "warm-up",
    "hard-yet",
    "frustrating",
    "do-not-cum",
    "better-not-have-cum",
    "balls-sore",
    "game-over",
    "stroke-tempo-little-slower",
    "stroke-tempo-little-faster",
    "stroke-tempo-slower",
    "stroke-tempo-faster",
    "stroke-tempo-stop",
    "stroke-tempo-start",
    "stroke-grip-light",
    "stroke-grip-normal",
    "stroke-grip-tight",
    "stroke-style-full",
    "stroke-style-head",
    "stroke-style-shaft",
    "stroke-hand-dominant",
    "stroke-hand-non-dominant",
    "stroke-clench",
    "stroke-double-speed",
    "teasing-strokes",
    "teasing-strokes-continue",
    "stroke-acceleration",
    "stroke-red-light-green-light",
    "stroke-red-light",
    "stroke-green-light",
    "cluster-strokes",
    "stroke-grip-challenge",
    "stroke-grip-challenge-light-grip",
    "stroke-grip-challenge-tight-grip",
    "squeeze-balls",
    "keep-squeezing-balls",
    "stop-squeezing-balls",
    "slap-balls",
    "slap-balls-harder",
    "flick-balls",
    "flick-cock",
    "flick-harder",
    "snap-rubber-band",
    "hard-snap-rubber-band",
    "attach-rubber-band",
    "detach-rubber-band",
    "bind-cock-balls",
    "apply-icy-hot",
    "apply-toothpaste",
    "palm-cock-head",
    "keep-palming-cock-head",
    "stop-palming-cock-head",
    "precum",
    "swallowed-precum",
    "edge",
    "edging",
    "edging-cooldown",
    "premature-edge",
    "premature-ruin",
    "cum",
    "post-orgasm-torture",
    "post-orgasm-torture-continue",
    "orgasm-denied",
    "ruined-orgasm",
    "ruined-orgasm-after",
    "cei-face-before-orgasm",
    "cei-mouth-before-orgasm",
    "cei-hand-before-orgasm",
    "cei-floor-before-orgasm",
    "cei-chest-before-orgasm",
    "cei-cup-before-orgasm",
    "cei-face-during-orgasm",
    "cei-mouth-during-orgasm",
    "cei-hand-during-orgasm",
    "cei-floor-during-orgasm",
    "cei-chest-during-orgasm",
    "cei-cup-during-orgasm",
    "cei-face-after-orgasm",
    "cei-mouth-after-orgasm",
    "cei-hand-after-orgasm",
    "cei-floor-after-orgasm",
    "cei-chest-after-orgasm",
    "cei-cup-after-orgasm",
    "take-breath",
    "hold-breath",
    "keep-holding-breath",
    "breathe",
    "attach-nipple-clamps",
    "detach-nipple-clamps",
    "ripoff-nipple-clamps",
    "pinch-nipple-clamps",
    "twist-nipple-clamps",
    "shake-nipple-clamps",
    "pull-nipple-clamps",
    "increase-nipple-clamp-weight",
    "decrease-nipple-clamp-weight",
    "stimulate-nipples-side-to-side",
    "stimulate-nipples-up-and-down",
    "stimulate-nipples-circles",
    "flick-nipples",
    "remove-butt-plug",
    "remove-inflated-butt-plug",
    "insert-butt-plug",
    "inflate-butt-plug",
    "deflate-butt-plug",
    "stop-vibrating-butt-plug",
    "start-vibrating-butt-plug",
    "max-vibrating-butt-plug",
    "open-eyes",
    "close-eyes",
    "lube-cock",
    "spit-cock",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59",
    "60",
    "61",
    "62",
    "63",
    "64",
    "65",
    "66",
    "67",
    "68",
    "69",
    "70",
    "71",
    "72",
    "73",
    "74",
    "75",
    "76",
    "77",
    "78",
    "79",
    "80",
    "81",
    "82",
    "83",
    "84",
    "85",
    "86",
    "87",
    "88",
    "89",
    "90",
    "91",
    "92",
    "93",
    "94",
    "95",
    "96",
    "97",
    "98",
    "99",
    "100",
    "101",
    "102",
    "103",
    "104",
    "105",
    "106",
    "107",
    "108",
    "109",
    "110",
    "111",
    "112",
    "113",
    "114",
    "115",
    "116",
    "117",
    "118",
    "119",
    "120",
    "121",
    "122",
    "123",
    "124",
    "125",
    "126",
    "127",
    "128",
    "129",
    "130",
    "131",
    "132",
    "133",
    "134",
    "135",
    "136",
    "137",
    "138",
    "139",
    "140",
    "141",
    "142",
    "143",
    "144",
    "145",
    "146",
    "147",
    "148",
    "149",
    "150",
    "151",
    "152",
    "153",
    "154",
    "155",
    "156",
    "157",
    "158",
    "159",
    "160",
    "161",
    "162",
    "163",
    "164",
    "165",
    "166",
    "167",
    "168",
    "169",
    "170",
    "171",
    "172",
    "173",
    "174",
    "175",
    "176",
    "177",
    "178",
    "179",
    "180",
    "181",
    "182",
    "183",
    "184",
    "185",
    "186",
    "187",
    "188",
    "189",
    "190",
    "191",
    "192",
    "193",
    "194",
    "195",
    "196",
    "197",
    "198",
    "199",
    "200",
]);
export const ScriptTriggerResponseSchema = z.array(z.object({
    id: ScriptTriggerSchema,
    dialogCount: z.number().optional(),
}));
