export class ApiResponseHandler {
    responsePromise;
    constructor(responsePromise) {
        this.responsePromise = responsePromise;
    }
    async noContent() {
        await this.responsePromise;
    }
    async json(schema) {
        const response = await this.responsePromise;
        const data = await response.json();
        if (schema) {
            return schema.parse(data);
        }
        return data;
    }
    async arrayBuffer() {
        const response = await this.responsePromise;
        return response.arrayBuffer();
    }
}
